/* src/app/admin/authentication/single-sign-on/single-sign-on.component.scss */
.single-sign-in-options {
  margin-top: 28px;
}
.single-sign-in-options .separator {
  background-color: #DAE1F9;
}
.single-sign-in-options .sign-in-using-lbl {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
}
.single-sign-in-options .sso-microsoft {
  margin-top: 28px;
  border: none;
}
.single-sign-in-options .sso-microsoft .sso-btn-label {
  padding-left: 8px;
  width: 100%;
}
.single-sign-in-options .sso-microsoft.btn {
  background-color: #0068FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.single-sign-in-options .sso-microsoft .icon {
  left: 12px;
  position: absolute;
  top: 10px;
}
.single-sign-in-options .sso-microsoft .icon svg {
  border-radius: 6px;
}
/*# sourceMappingURL=single-sign-on.component-6E53HWH3.css.map */
