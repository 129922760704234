/* src/app/admin/authentication/maintenance/maintenance.component.scss */
.maintenance-wrap {
  display: flex;
  min-height: 100vh;
  background-color: #F4F7FE;
}
.maintenance-wrap-right {
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3% 20%;
}
.maintenance-wrap-right .logo {
  margin-bottom: 40px;
}
@media only screen and (max-width : 1023px) {
  .maintenance-wrap-right {
    padding: 40px;
  }
}
.maintenance-wrap-right h1 {
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #2B3674;
  margin-bottom: 23px;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-family: "Sora", sans-serif;
  text-align: center;
}
@media only screen and (max-width : 1023px) {
  .maintenance-wrap-right h1 {
    font-size: 24px;
    line-height: 32px;
  }
}
.maintenance-wrap-right h1.margin-0 {
  margin-bottom: 10px;
}
.maintenance-wrap-right h6 {
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #0068FF;
  margin-bottom: 23px;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-family: "Sora", sans-serif;
  text-align: center;
}
@media only screen and (max-width : 1023px) {
  .maintenance-wrap-right h6 {
    font-size: 24px;
    line-height: 32px;
  }
}
.maintenance-wrap-right .from-team {
  color: #50BFA4;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: bold;
  text-align: center;
}
/*# sourceMappingURL=maintenance.component-O44PBUQL.css.map */
