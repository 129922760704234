import * as i0 from '@angular/core';
import { Injectable, Component, NgModule } from '@angular/core';
import { PropertiesPluginIdentifier, AnalyticsPluginIdentifier } from '@microsoft/applicationinsights-common';
import { isFunction, arrForEach, BaseTelemetryPlugin, onConfigChange, isArray, getLocation, generateW3CId, _throwInternal } from '@microsoft/applicationinsights-core-js';
import dynamicProto from '@microsoft/dynamicproto-js';
import { NavigationEnd } from '@angular/router';
import { objDeepFreeze } from '@nevware21/ts-utils';
class ApplicationinsightsAngularpluginErrorService {
  constructor() {
    this.errorServices = [];
    if (ApplicationinsightsAngularpluginErrorService.instance === null) {
      ApplicationinsightsAngularpluginErrorService.instance = this;
    }
  }
  set plugin(analyticsPlugin) {
    this.analyticsPlugin = analyticsPlugin;
  }
  clearErrorHandlers() {
    this.errorServices = [];
  }
  addErrorHandler(errorService) {
    if (errorService && isFunction(errorService.handleError)) {
      this.errorServices.push(errorService);
    }
  }
  removeErrorHandler(errorService) {
    if (errorService && isFunction(errorService.handleError)) {
      const idx = this.errorServices.indexOf(errorService);
      if (idx !== -1) {
        this.errorServices.splice(idx, 1);
      }
    }
  }
  handleError(error) {
    if (this.analyticsPlugin) {
      this.analyticsPlugin.trackException({
        exception: error
      });
    }
    if (this.errorServices && this.errorServices.length > 0) {
      arrForEach(this.errorServices, errorService => {
        if (isFunction(errorService.handleError)) {
          errorService.handleError(error);
        }
      });
    }
  }
}
ApplicationinsightsAngularpluginErrorService.instance = null;
ApplicationinsightsAngularpluginErrorService.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginErrorService,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
ApplicationinsightsAngularpluginErrorService.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginErrorService,
  providedIn: "root"
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginErrorService,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
let undefValue;
const defaultAngularExtensionConfig = objDeepFreeze({
  router: {
    blkVal: true,
    v: undefValue
  },
  errorServices: {
    blkVal: true,
    v: undefValue
  }
});
// eslint-disable-next-line @angular-eslint/component-class-suffix
class AngularPlugin extends BaseTelemetryPlugin {
  constructor(_injector) {
    super();
    this._injector = _injector;
    this.priority = 186;
    this.identifier = "AngularPlugin";
    let _analyticsPlugin;
    let _propertiesPlugin;
    let _angularCfg;
    let _eventSubscription;
    let _isPageInitialLoad;
    let _prevRouter;
    let _errorServiceInstance;
    dynamicProto(AngularPlugin, this, (_self, _base) => {
      const _initDefaults = () => {
        _analyticsPlugin = null;
        _propertiesPlugin = null;
        _angularCfg = null;
        _eventSubscription = null;
        _isPageInitialLoad = true;
        _prevRouter = undefValue;
      };
      _initDefaults();
      _self.initialize = (config, core, extensions, pluginChain) => {
        super.initialize(config, core, extensions, pluginChain);
        _self._addHook(onConfigChange(config, details => {
          let ctx = _self._getTelCtx();
          _angularCfg = ctx.getExtCfg(_self.identifier, defaultAngularExtensionConfig);
          _propertiesPlugin = core.getPlugin(PropertiesPluginIdentifier)?.plugin;
          _analyticsPlugin = core.getPlugin(AnalyticsPluginIdentifier)?.plugin;
          if (_angularCfg.useInjector && _injector) {
            _errorServiceInstance = this._injector.get(ApplicationinsightsAngularpluginErrorService);
          }
          _errorServiceInstance = _errorServiceInstance ? _errorServiceInstance : ApplicationinsightsAngularpluginErrorService.instance;
          // two instance of errorService
          if (_analyticsPlugin) {
            if (_errorServiceInstance !== null) {
              _errorServiceInstance.plugin = _analyticsPlugin;
              if (_angularCfg.errorServices && isArray(_angularCfg.errorServices)) {
                _errorServiceInstance.clearErrorHandlers();
                arrForEach(_angularCfg.errorServices, errorService => {
                  _errorServiceInstance.addErrorHandler(errorService);
                });
              }
            }
          }
          if (_angularCfg.router !== _prevRouter) {
            // router is changed, or has not been initialized yet
            // unsubscribe previous router events
            if (_eventSubscription) {
              _eventSubscription.unsubscribe();
            }
            if (_angularCfg.router) {
              // only track page view if it is the initial page load for this plugin
              if (_isPageInitialLoad) {
                const pageViewTelemetry = {
                  uri: _angularCfg.router.url
                };
                _self.trackPageView(pageViewTelemetry);
              }
              // subscribe to new router events
              _eventSubscription = _angularCfg.router.events.subscribe(event => {
                if (_self.isInitialized()) {
                  if (event instanceof NavigationEnd) {
                    // for page initial load, do not call trackPageView twice
                    if (_isPageInitialLoad) {
                      _isPageInitialLoad = false;
                      return;
                    }
                    const pvt = {
                      uri: _angularCfg.router.url,
                      properties: {
                        duration: 0
                      } // SPA route change loading durations are undefined, so send 0
                    };
                    _self.trackPageView(pvt);
                  }
                }
              });
            }
            _prevRouter = _angularCfg.router;
          }
        }));
        // for test purpose only
        _self["_getDbgPlgTargets"] = () => _angularCfg;
        _self["_getErrorService"] = () => _errorServiceInstance;
      };
      _self.trackPageView = pageView => {
        if (_analyticsPlugin) {
          const location = getLocation();
          if (_propertiesPlugin && _propertiesPlugin.context && _propertiesPlugin.context.telemetryTrace) {
            _propertiesPlugin.context.telemetryTrace.traceID = generateW3CId();
            _propertiesPlugin.context.telemetryTrace.name = location && location.pathname || "_unknown_";
          }
          _analyticsPlugin.trackPageView(pageView);
        } else {
          _throwInternal(_self.diagLog(), 1 /* eLoggingSeverity.CRITICAL */, 64 /* _eInternalMessageId.TelemetryInitializerFailed */, "Analytics plugin is not available, Angular plugin telemetry will not be sent: ");
        }
      };
      _self._doTeardown = (unloadCtx, unloadState, asyncCallback) => {
        if (_analyticsPlugin && _errorServiceInstance !== null) {
          _errorServiceInstance.plugin = null;
          if (_angularCfg) {
            if (_angularCfg.errorServices && Array.isArray(_angularCfg.errorServices)) {
              _errorServiceInstance.clearErrorHandlers();
            }
          }
        }
        if (_eventSubscription) {
          _eventSubscription.unsubscribe();
          _eventSubscription = null;
        }
        _initDefaults();
      };
    });
  }
  /**
   * Add Part A fields to the event
   *
   * @param event The event that needs to be processed
   */
  processTelemetry(event, itemCtx) {
    this.processNext(event, itemCtx);
  }
  initialize(config, core, extensions, pluginChain) {
    // @DynamicProtoStub -- DO NOT add any code as this will be removed during packaging
  }
  trackPageView(pageView) {
    // @DynamicProtoStub -- DO NOT add any code as this will be removed during packaging
  }
}
AngularPlugin.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: AngularPlugin,
  deps: [{
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
AngularPlugin.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "15.2.10",
  type: AngularPlugin,
  selector: "lib-applicationinsights-angularplugin-js",
  usesInheritance: true,
  ngImport: i0,
  template: "",
  isInline: true
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: AngularPlugin,
  decorators: [{
    type: Component,
    args: [{
      selector: "lib-applicationinsights-angularplugin-js",
      template: ""
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }];
  }
});
class ApplicationinsightsAngularpluginJsModule {}
ApplicationinsightsAngularpluginJsModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginJsModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
ApplicationinsightsAngularpluginJsModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "14.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginJsModule,
  declarations: [AngularPlugin],
  exports: [AngularPlugin]
});
ApplicationinsightsAngularpluginJsModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginJsModule,
  providers: [ApplicationinsightsAngularpluginErrorService]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "15.2.10",
  ngImport: i0,
  type: ApplicationinsightsAngularpluginJsModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [AngularPlugin],
      imports: [],
      exports: [AngularPlugin],
      providers: [ApplicationinsightsAngularpluginErrorService]
    }]
  }]
});

/*
 * Public API Surface of applicationinsights-angularplugin-js
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularPlugin, ApplicationinsightsAngularpluginErrorService, ApplicationinsightsAngularpluginJsModule };
