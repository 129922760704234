/* src/app/admin/authentication/profile/profile.component.scss */
.default-user-avatar {
  height: 55px;
  width: 55px;
  border-radius: 100%;
}
.default-user-avatar-edit-img {
  top: 35px;
  left: 35px;
}
/*# sourceMappingURL=profile.component-XEZALLNL.css.map */
